// Help popups for form fields

.tab-container{
    padding: 0;
}

.info-help{
    position: relative;

    .help-bubble{
        position: absolute;
        right: 15px;
        top: 0;
        color: #9e9e9e;
        font-size: 18px;
        transition: 0.2s all ease-in-out;
        opacity: 0;
        z-index: 2;
        // animation: bounce 2s infinite;

        &:hover{
            color: $green;
            cursor: pointer;
        }
    }

    .help-block{
        display: none;
    }


    &:hover{
        .help-bubble{
            opacity: 1;
        }
    }
}


.help-pop-up{
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba($color: #ECF0F4, $alpha: 0.8);
    top: 0;
    left: 0;
    transition: 0.3s all ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 30px;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    pointer-events: none;

    .help-ctn{
        width: 500px;
    
        border-radius: 3px;
        background-color: white;
        box-shadow: 0 15px 30px 0 rgba(34,44,50,0.1);
        overflow: hidden;
        transition: 0.4s all cubic-bezier(0,.51,.01,.92) 0.3s;
        transform: translateY(300px);
        opacity: 0;

        .help-head{
            background-color: $green;
            padding: 20px 30px;
            position: relative;

            .title{
                font-size: 22px;
                line-height: 28px;
                color: white;
                margin: 0;
            }

            .close{
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                color: white;
                opacity: 1;
                font-size: 22px;
            }
        }

        .help-inner{
            padding: 40px 50px;

            .title{
                font-weight: bold;
                color: #333333;
                line-height: 24px;
                font-size: 16px;
                margin-top: 0; 
            }

            .text{
                color: #333333;
                font-size: 14px;
                line-height: 24px;
            }
        }
    }

    &.open{
        visibility: visible;
        opacity: 1;
        z-index: 9999;
        pointer-events: inherit;

        .help-ctn{
            transform: translateY(0);
            opacity: 1;
        }
    }

}


@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-5px);
    }
    60% {
        transform: translateY(-2px);
    }
}