.login-ctn {
   .login-brand {
      display: flex;
      align-content: center;
      justify-content: center;
      padding-top: 40px;
      padding-bottom: 30px;

      img { max-width: 260px; }
   }

   form {
      padding: 0px 30px 20px 30px; 
      
      .controls {
         display: flex;
         flex-wrap: wrap;
         gap: 10px;
      }
   }
}
