body.reports {
    span.green {
        color: $green;
    }
    .content-box-header {
        padding-top: 80px !important;
    }
    .content-box-content {
        p {
            text-align: center;
            font-size: large;
            &.msg-3 {
                margin-top: 50px;
            }
        }
    }
    .content-box-footer {
        margin-top: 35px;
        display: flex;
        flex-wrap: nowrap;
        div {
            justify-content: space-between;
            width: 50%;
            &.right {
                text-align: right;
            }
            a {
                color: $green;
            }
        }
    }
}