.modal.note-modal {
  &.show:before{
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color:rgba(0,0,0,.5);
  }
  .modal-dialog {
    box-shadow: 0 3px 9px rgba(0,0,0,.3);

    .modal-content { 
      box-shadow: none; 

      .modal-body {
        .form-check-input {
          position: relative;
        }
      }
    }
  }
}

.modal-backdrop.show{
  display: none !important;
}

