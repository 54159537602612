body.gateway {
        background-color: $body-background-color;

    // Header overrides
	.app-header {
		height: 120px;
	}

    // Fix flex bug in header
    .container { &::before, &::after { content: none; } }

    // Override menu margin
    .main { margin: 0; }

    .content-box {
        .content-box-picto {
            padding-top: 80px;
            text-align: center;
        }

        .content-box-header {
            .title {
                color: #2A2B2D;
                font-size: 24px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 33px;
                text-align: center;
                margin-bottom: 20px;
                text-transform: uppercase;
            }
        }

        .content-box-content {
            padding: 0 100px 100px;
            background: none;
            max-width: 800px;
            margin: 0 auto;

            p {
                text-align: center;
                font-size: large;
                &.msg-1 {
                    padding-bottom: 15px;
                }
                &.msg-3 {
                    margin-top: 50px;
                    text-transform: uppercase;
                    font-weight: bold;
                    a {
                        color: $green;
                    }
                }
            }
        }
    }

    @media (max-width: $medium) {
        .content-box .content-box-content {
            padding-right: 30px;
            padding-left: 30px;
        }
    }

    @media (max-width: $small) {
        .content-box {
            margin-top: 20px;
        }
    }

    @media (max-width: $xs-small) {
        .content-box {
            margin-top: 20px;
        }
    }
}