/***** FONTS ******/
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700');
// @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');
$primaryFont: 'Open Sans', sans-serif;


/***** Colors ******/
$green: #008060;
$dark-green: #005741;
$red: #EF5656;
$black: #000000;
$text-color: #3D3F43;
$text-color-light: #6B7076;
$text-color-dark: #1e2022;
$icon-color: #858B91;

/***** Transition ******/
$mainTransition: all 0.4s cubic-bezier(.55,.22,.36,.9);
$quickTransition: all 0.2s;
$fadeTransition: all 0.7s cubic-bezier(.55,.22,.36,.9);

/***** Breakpoints ******/
$xs-small: 550px;
$small: 767px;
$medium: 992px;
$large: 1200px;

// Typography
$font-family-sans-serif: "Open Sans", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;

// Dimensions
$header-height: 60px;
$sidebar-width: 260px;

// Colors
$body-background-color: #D7E0E6;
$sidebar-hover-color: #EEF0F2;
$table-border-color: #E9ECEF;
$content-shadow: 0 8px 16px 0 rgba(0,0,0,0.05);