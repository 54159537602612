.reset-brand {
   display: flex;
   align-content: center;
   justify-content: center;
   padding-bottom: 30px;
   img {
      max-width: 260px;
   }
}

.email-ctn {
   background-color: rgb(238, 240, 242);
   padding: 40px 20px;
}