body.reports {
    background-color: $body-background-color;

    // Header overrides
	.app-header {
		height: 120px;
	}

    // Fix flex bug in header
    .container { &::before, &::after { content: none; } }

    // Override menu margin
    .main { margin: 0; }

    // Buttons overrides
    .btn-ctn {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 12px;
        padding: 30px 0;
    }
    .btn-primary {
        height: 60px;
        padding: 0 50px;
        text-transform: uppercase;
        max-width: 100%;
        white-space: break-spaces;
        padding: 20px;
    }
    #main-search {
        white-space: normal;
    }

    // Form elements overrides
    .form-group {
        & > label {
            margin: 20px 0;
            color: #2A2B2D;
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
        }
    }

    // Capitalized link
    .main-link {
        display: inline-block;
        position: relative;
        margin: 0 0 50px;
        padding-left: 20px;
        color: $green;
        font-size: 13px;
        font-weight: 900;
        letter-spacing: 1px;
        line-height: 16px;
        text-transform: uppercase;
        text-decoration: none !important;
        transition: $quickTransition;
    
        &:hover {
            color: $dark-green;
        }
    
        &::before {
            content: "";
            width: 10px;
            height: 15px;
            position: absolute;
            top: 1px;
            left: 0;
            background-image: url('../images/arrow-left.svg');
            background-repeat: no-repeat;
            background-size: contain;
            transition: $quickTransition;
        }
    
        &.-back {
            &:hover::before {
                left: -5px;
            }
        }
    }

    header {
        height: 120px;
        margin: 0 !important;

        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;
        }

        .logo {
            width: 50%;
            max-width: 240px;
            min-width: 130px;

            img { width: 100%; }
        }
        
        .info-ctn {
            text-align: right;

            .title {
                color: #FFFFFF;
                font-size: 24px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 33px;
                text-transform: uppercase;
            }

            a {
                display: inline-block;
                margin-top: 4px;
                margin-left: 18px;
                text-align: right;
                color: #C0EFCE;
                font-size: 16px;
            }
        }
    }
    
    .content-box .content-box-header {
        .title {
            color: #2A2B2D;
            font-size: 24px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 33px;
            text-align: center;
            margin-bottom: 20px;
        }
    }

    .content-box .content-box-content {
        padding: 0 100px 100px;
        background: none;

        .search-ctn {
            label {
                font-size: 18px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 24px;
            }

            input {
                height: 60px;
                padding: 20px 30px;
                font-size: 18px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 18px;
            }
        }

        .results-ctn {
			margin-top: 60px;

			.subtitle {  
				margin-bottom: 50px;
				font-size: 24px;
				font-weight: 600;
				letter-spacing: 0;
				line-height: 33px;
				text-align: center;
			}

			.articles {
				border: 1px solid #CCCCCC;
				border-radius: 5px;
			}

			.property {
                padding: 20px 30px;
				border-bottom: 1px solid #CCCCCC;
				&:last-child { border-bottom: 0px none; }

                .property-image {
                    max-width: 200px;
                    img { width: 200px; }
                }

                .property-info {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 15px;

                    * { 
                        color: $text-color-dark;
                    }

                    .title {
                        width: 50%;
                        margin: 0;
                        font-size: 16px;
                        line-height: 22px;
                        font-weight: 600;
                        
                    }

                    .mls {
                        margin: 0;
                        font-size: 16px;
                        line-height: 22px;
                        font-weight: 600;
                    }

                    .link {
                        position: relative;
                        padding-right: 30px;
                        text-decoration: none;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 22px;
                        text-align: right;

                        &:after{
                            position: absolute;
                            width: 10px;
                            height: 15px;
                            content: "";
                            background-image: url("../images/arrow-right.svg");
                            background-repeat: no-repeat;
                            background-size: contain;
                            top:50%;
                            transform: translateY(-50%);
                            right: 0;
                        }
                    }

                    .ext_address {
                        font-size: 18px;
                        line-height: 24px;
                        margin-top: 0;
                        font-size: 22px;
                        line-height: 26px;
                        font-weight: 600;

                        @media (max-width: 600px) {
                            font-size: 16px;
                            line-height: 22px;
                        }
                    }

                    .back {
                        color: $green;
                        text-decoration: underline;
                        display: inline-block;
                        margin-top: 0px;
                        font-size: 18px;
                        line-height: 24px;
                        transition: $mainTransition;

                        &:hover{
                            opacity: 0.7;
                        }
                        
                        @media (max-width: 600px) {
                            font-size: 16px;
                            line-height: 22px;
                        }
                    }

                    @media (max-width: 600px) {
                        width: 100%;
                        margin-left: 0;
                        margin-top: 20px;
                    }
                }

                .property-img{
                    margin-right: 20px;

                    .image{
                        max-width: 150px;
                    }

                    @media (max-width: 600px) {
                        width: 100%;

                        .image{
                            max-width: 100%;
                        }
                    }
                }
			}
		}
    }

    @media (max-width: $medium) {
        .content-box .content-box-content {
            padding-right: 30px;
            padding-left: 30px;
        }
    }

    @media (max-width: $small) {
        header {
            .info-ctn {
                display: flex;
                flex-direction: column;

                .title {
                    font-size: 16px;
                    line-height: 18px;
                }

                a {
                    font-size: 12px;
                    line-height: 14px;
                }
            }
        }
    }

    @media (max-width: $xs-small) {
        .content-box .content-box-content .results-ctn .property {
            display: flex;
            flex-direction: row-reverse;

            .property-info {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }
}
