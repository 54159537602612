// Fonts
@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,600');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700');


// Variables
@import "configs/variables";
@import "configs/reset";
@import "configs/grid"; 
@import "configs/common";
@import "configs/icons"; 

// Components overrides
@import "components/summernote";



// Section-specific styles
@import "components/reports";
@import "components/maintenance";
@import "components/gateway";

// AUTH
@import "components/auth/login";
@import "components/auth/register";
@import "components/auth/reset";

// Bootstrap 
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Component
@import "components/header";
@import "components/footer";
@import "components/sidebar";
@import "components/main-content";
@import "components/crud";
@import "components/help";
@import "components/dashboard";