body, body.skin-green {
    position: relative;
	background-color: $body-background-color;
    color: $text-color;
} 

a { 
    transition: $mainTransition;
    text-decoration: none !important;
}
 
// Buttons
.btn {
    display: flex !important;
    align-items: center;
    box-sizing: border-box;
    width: max-content;
    height: 35px;
    min-height: 35px;
	  padding: 0;
    border-radius: 5px;
    border: 0 none;
    text-align: center;
    transition: $mainTransition;
    font-family: $primaryFont;
    padding: 0 18px 2px;
    box-shadow: 0 4px 6px 0 rgba(50,50,93,0.11), 0 1px 3px 0 rgba(0,0,0,0.08);

    i { transition: $mainTransition; }

    &.btn-default {
        color: $text-color-light;
        background-color: #EEF0F2;
        border: 0 none;
        font-size: 13px;
        line-height: 22px;
        font-weight: 600;
    }

    // Green button
    &.btn-primary, &.btn-success {
        // min-height: 45px;
        background-color: $green !important;
        color: white !important;
        border: none;
        font-size: 13px;
        line-height: 22px;
        font-weight: 600;
        &:hover, &:focus, &:focus-visible, &:active { background-color: $dark-green !important; }
    }

    // Shadow button
    &.btn-outline-primary {
        color: $text-color-light;
        background-color: #EEF0F2;
        border: none;
        
        &:hover { 
            color: white;
            background-color: $dark-green;
        }
    }

    // CRUD table buttons
    // Moved to table section
    &.btn-link, &.btn-xs {  // btn-xs test for page Neighborhoods
        // display: inline-block !important;
        // width: 45px !important;
        // height: 35px !important;
        // min-height: 0;
        // padding: 0;
        // border: 1px solid rgba(133, 139, 145, 0.3);
        // color: rgba(0,0,0,0) !important;
        // box-shadow: none;
        // background: none;

        // i { 
        //     width: 100%;
        //     color: $icon-color;
        //     font-size: 22px;
        //     line-height: 34px;
        // }

        // &:hover {
        //     border-color: $green;
        //     background-color: $green;
        //     i { color: white; }
        // }

        // &.disabled {
        //   pointer-events: none !important;
        // }
    }

    // Inline create
    &.inline-create-button {
      position: absolute;
      top: -5px;
      right: 15px;
      padding: 0;
      color: $green;

      span.la { display: block !important; }

      &:hover {
        text-decoration: none;
        color: $dark-green
      }
    }

    // Hide the following: 
        // In Blog CRUD, not working?
        // &.inline-create-button, 
        // Button default icon
        span.la,
        // Doubled dropdown caret
        &.dropdown-toggle::after 
        { display: none !important; }
}

// CRUD table buttons
table td:last-child .btn {
  display: inline-block !important;
  width: 45px !important;
  max-width: 45px !important;
  height: 35px !important;
  min-height: 0;
  padding: 0;
  border: 1px solid rgba(133, 139, 145, 0.3);
  color: rgba(0,0,0,0) !important;
  box-shadow: none;
  background: none;
  overflow: hidden;
  user-select: none;

  i { 
      width: 100%;
      color: $icon-color;
      font-size: 22px;
      line-height: 34px;
  }

  &:hover {
      border-color: $green;
      background-color: $green;
      i { color: white; }
  }

  &.disabled {
    pointer-events: none !important;
  }
}

// Hide arrows on number fields
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
  /* Firefox */
}

// Form elements
.form-group {
    margin-bottom: 30px !important;

    label { font-weight: 600; }

    // Rounded fields basic properties
    .form-control[type="text"], 
    .form-control[type="email"], 
    .form-control[type="tel"],
    .form-control[type="url"],
    .form-control[type="number"],
    .form-control[type="password"],
    .form-control[type="date"],
    .form-control[type="datetime-local"],
    .select2-selection__rendered, 
    .backstrap-file,
    .iconpicker,
    textarea,
    select {
        height: 45px;
        border-radius: 5px;
        border: 0 none;
        box-shadow: 0 1px 3px 0 rgba(50,50,93,0.2), 0 1px 0 0 rgba(0,0,0,0.02);
        color: rgba(0, 0, 0, 0.6);
    }

    // File input (Publications)
    .backstrap-file {
        overflow: hidden;

        label {
            height: 100%;
            margin: 0;
            padding: 10px;

            &::after {
                height: 100%;
                padding: 10px;
            }
        }
    }

    // Adjust rounded field style for select2 elements
    select ~ span.select2.select2-container {
        display: block;
        height: 45px;

        .select2-selection.select2-selection--single {
            padding: 0;
            height: 100%;
            border: 0 none !important;

            .select2-selection__rendered { 
                padding: 12px;
                line-height: 20px;
            }
        }

        // Inline clear button
        .select2-selection__clear {
            position: relative;
            top: 0;
            right: 10px;
            font-size: 24px;
            &::after { display: none; }
        }

        .select2-selection__choice {
            height: 32px;
            padding: 10px !important;
            line-height: 10px;
        }

        .select2-search__field {
            height: 100% !important;
            padding-top: 5px !important;
        }
    }

    // Adjust select2 arrow
    .select2-container--default .select2-selection--single .select2-selection__arrow {
        top: 10px;
        right: 10px;
    }

    // Regular inline checkbox
    .checkbox, .radio { 
        position: relative;
        display: block;
        margin-top: 0px;
        margin-bottom: 12px;

        label { padding: 0; }

        input[type="checkbox"], input[type="radio"] {
            appearance: none;
            width: 20px;
            height: 20px;
            position: relative;
            top: 4px;
            margin-right: 10px;
            margin-left: 0;
            border-radius: 5px;
            border: 1px solid #ccc;
            background-color: white;
            box-shadow: 0 1px 3px 0 rgba(50, 50, 93, 0.2), 0 1px 0 0 rgba(0, 0, 0, 0.02);
            transition: $quickTransition;
            
            &:checked { 
                background-color: $green;
                border-color: $green;

                &::after {
                    content: url('../../images/icon/checked.svg');
                    display: inline-block;
                    position: absolute;
                    left: 5px;
                    top: -2px;
                    transform: scale(1.2);
                }
            }
        }

        input[type="radio"] {
            border-radius: 50%;

            &:checked {
                background: none;

                &::after {
                    content: "";
                    height: 12px;
                    width: 12px;
                    left: 3px;
                    top: 3px;
                    background-color: $green;
                    border-radius: 50%;
                }
            }
        }
    }
    
    // WYSIWYG textarea
    .note-frame {
        background-color: white !important;
        border-radius: 5px;
        box-shadow: 0 1px 3px 0 rgba(50,50,93,0.2), 0 1px 0 0 rgba(0,0,0,0.02);

        .note-toolbar .btn {
            height: auto;
            padding: 4px 10px;
            border-radius: 0;
            font-size: inherit;
            box-shadow: none;
            background: none;
            border: 0 none;
        }
    }

    // Video field
    .video-previewSuffix {
        height: 100%;
        position: absolute;
        right: 0;
        z-index: 99;

        .video-previewLink, .video-previewImage {
            width: 45px;
            height: 45px;

            i.la {
                font-size: 30px;
                margin-top: 4px;
            }
        }
    }

    // Repeater field
    .container-repeatable-elements {
        .repeatable-element {
            margin: 0 0 10px !important;
            padding: 12px 0 0 30px !important;
            border: 0 none;
            box-shadow: none;
            background-color: #D5D8DB;

            .controls {
                left: 7px;
                top: 40px;
            }
        }
    }

    // Dropzone
    .jumbotron.how-to-create {
        margin: 0;
        padding: 0;
    }

    // Field units (Quartiers - données partagées)
    .input-group-append .input-group-text {
        display: flex;
        justify-content: right;
        min-width: 100px;
        height: 100%;
        position: absolute;
        left: auto;
        right: 6px;
        top: 0;
        padding: 0;
        z-index: 999;
        border: 0 none;
        background: none;
        font-size: 14px;
        color: #AAA;
        text-align: right;

        // Colorpicker field
        &.colorpicker-input-addon {
            min-width: auto;
            top: 2px;
            left: -45px;
            border: none;

            i { 
                height: 32px;
                width: 32px;
                border-radius: 5px;
            }
        }

        .btn-copy{
            color: $text-color;

            &:hover{
                color: black;
            }
        }
    }

    .input-group{
        display: flex !important;
    
        .form-control{
            float: none;
            width: auto;
            display: block;
        }
    
        .input-group-prepend{
            
            .input-group-text{
                font-size: 14px;
                color: rgba(0, 0, 0, 0.6);
                background-color: white;
                border: 0;
            }
        }
    
        
    }

    // Iconpicker
    .iconpicker {
        width: 80px;
    
        span.caret {
            position: relative;
            right: 0px;
        }
    
        i.custom {
            margin: auto;
            font-size: 19px;
        }
    }

    // Slider
    .slidecontainer {
        padding-top: 10px;

        input[type=range] {
            appearance: none;
            background-color: #D9DEE4;
            border-radius: 15px;
            height: 10px;

            /* WebKit/Blink */
            &::-webkit-slider-thumb {
                -webkit-appearance: none;
                border: 0 none;
                height: 26px;
                width: 26px;
                border-radius: 50px;
                background: $green;
                cursor: pointer;
                margin-top: -14px;
            }
          
            /* Firefox */
            &::-moz-range-thumb {
                border: 0 none;
                height: 26px;
                width: 26px;
                border-radius: 50px;
                background: $green;
                cursor: pointer;
            }
            
            /* IE */
            &::-ms-thumb {
                border: 0 none;
                height: 26px;
                width: 26px;
                border-radius: 50px;
                background: $green;
                cursor: pointer;
            }
        }

        .slider-controls {
            display: flex;
            justify-content: space-between;
            margin-top: 14px;
        }
    }

    // Fix wysiwyg bullets
    .note-editing-area {
        ul { list-style-type: disc; }
    }
}

// Iconpicker - bottom over element
.iconpicker-popover {
    .btn-icon:not(.btn-primary) {
        background: none;
        box-shadow: none;

        &:hover {
            background-color: $green;
            i.custom { color: white; }
        }
    }

    .btn-primary span { margin: auto; }

    i.custom {
        margin: auto;
        font-size: 19px;
    }
}

// Datetime picker (reports)
// TODO: Optimize?
.xdsoft_datetimepicker {
	// display: block !important;	// Uncomment to always show calendar for testing purposes
	width: auto;
	top: 270px;
	left: 40px;	
	padding: 28px 20px;
	border: 1px solid #CCCCCC;
	border-radius: 5px;
	box-shadow: none;

	.xdsoft_timepicker {
		.xdsoft_prev,.xdsoft_next { visibility: hidden; }

		.xdsoft_scroller_box {
			border-radius: 5px;
			height: 200px;
		}

		.xdsoft_time {
			background: none !important;
			&:hover { background: rgb(1, 90, 1)!important; }
		}

		.xdsoft_current { background: green !important; }
	}

	.xdsoft_datepicker {
		width: auto;

		.xdsoft_monthpicker {
			display: flex;
			justify-content: space-between;

			.xdsoft_next, .xdsoft_prev {
                background-color: #ff7ff9;
                border-radius: 50%;
                transform: scaleY(0.7);
				color:white;
				padding-bottom:5px;
				filter: invert(100%);
				opacity:1;
				&:hover { opacity:0.7; }
			}

			.xdsoft_label {
				width: auto;
				color: #2A2B2D;
				font-family: "Open Sans";
				font-size: 15px;
				font-weight: bold;
				letter-spacing: 0.59px;
				line-height: 20px;
				text-align: center;
				text-transform: uppercase;
			}
		}

		.xdsoft_calendar {
			th {
				background: none;
				border: 0px none;
				color: #2A2B2D;
				font-family: "Open Sans";
				font-size: 0;
				font-weight: 600;
				letter-spacing: 0;
				line-height: 19.01px;
				text-align: center;
	
				&::first-letter { font-size: 16px; }
			}
	
			td {
				background: none !important;
				border: 0px none;
				padding: 10px;
				box-shadow: none !important;
				color: #5F5A5A;
				font-size: 14px;
				letter-spacing: 0;
				line-height: 17px;
				text-align: center;
	
				&:hover {
					color: #5F5A5A !important;
					background: none !important;
				}
	
				&.xdsoft_current {
					position: relative;
					color: white !important;
					font-weight: normal;
	
					&::after {
						content: "";
						display: block;
						width: 38px;
						height: 38px;
						position: absolute;
						top: 0;
						left: -1px;
						z-index: -1;
						background-color: $green;
						border-radius: 50%;
					}
				}
			}
		}
	}

    .xdsoft_calendar td.xdsoft_today { color: $green; }

    .xdsoft_calendar td.xdsoft_default, 
    .xdsoft_calendar td.xdsoft_current, 
    .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current {
        background: $green;
        color: white;
    }
}

// Fix CRUD tables mobile-modal styling, which is somehow overridden by fucking Bootstrap
.modal.fade.show {
    background-color: rgba(0,0,0,0.5);
    opacity: 1 !important;
    
    .modal-dialog { 
        margin: 100px auto;
        transform: none;
    }
}

.colorpicker-bs-popover {
    opacity: 1 !important;
}

// Error page
.e404 {
  margin-left: -275px !important;
}

// Inscriptions > Facturation date range
.daterangepicker {
  .ranges li.active, td.active {
    background-color: $green !important;
  }
  .drp-buttons {
    display: flex !important;
    flex-direction: row-reverse !important;
    justify-content: space-between !important;
    align-items: center !important;
    .drp-selected { margin-left: auto !important; }
  }
}

// Set a maximum height for image upload preview fields
.cropperImage img {
  max-height: 400px !important;
}



//AADMIN UPDATE CSS MODIF

.block-editor-block-list__layout ul {
    list-style: unset !important;
}

// COLOR PICKERS

.form-group .input-group-append .input-group-text.colorpicker-input-addon {
    top: 0;
    left: auto;
    min-width: 0;
}