.app-header.navbar {
	height: $header-height;
	margin: 0;
	background-color: $green !important;  // Override bg-green global class
	border: 0 none;
	border-radius: 0;
    z-index: 999;

	// Logo
	.navbar-brand {
		justify-content: left;
		width: $sidebar-width;
		height: $header-height;
		position: absolute;
		left: 0;
		margin: 0;
		padding: 6px 20px;
		background-color: white;
		img { height: 100%; }
	}

	// User menu
	.nav.navbar-nav {
		height: $header-height;
		margin: 0;

		.user-menu {
			display: flex;
			height: 100%;
			padding: 0 !important;

			a {
				display: flex;
				align-items: center;
				padding: 0 20px;
				color: white;

				&:not(.user-info):hover, 
				&:not(.user-info):active,
				&:not(.user-info):focus { background-color: $dark-green; }

				&.user-info {
					width: auto;
					height: 100%;
					margin-right: auto;

					.user-name { text-align: left; }

					img { 
						// content: url('../images/user.svg');
						position: relative;
						width: 40px;
						height: 40px;
					}
				}
			}
		}
		&.d-md-down-none {
			position: absolute;
			left: $sidebar-width;
			margin-left: 10px;
			font-size: 1.2em;
			color: #fff;
			opacity: 1;
			padding-left: 10px;
			a {
				display: flex;
				align-items: center;
				padding: 0;
				color: white;
				i {
					padding-right: 5px;
				}
			}
			.dropdown-menu {
				a{
					display: flex;
					color: black;
					padding: 10px;
				}
			}
		}
	}

	// Menu toggler
	.navbar-toggler {
		position: absolute;
		top: 16px;
		z-index: 999;
		transition: $mainTransition;
	}

	// Hide menu toggler on desktop
	@media (min-width: $medium) {
		.navbar-toggler { display: none !important; }
	}

	// Stack logo on top of menu on mobile
	@media (max-width: $medium) {
		height: calc(#{$header-height} * 2);

		.navbar-brand {
			width: 100%;
			img { margin: auto; }
		}

		.nav.navbar-nav {
			width: 100%;
			position: absolute;
			bottom: 0;
			right: 0;
		}
	}
	
	// 
	@media (max-width: $xs-small) {
		.nav.navbar-nav {
			width: 100%;
			position: absolute;
			bottom: 0;
			right: 0;

			.user-menu {
				width: 100%;
				justify-content: space-between;

				a {
					padding: 0 5px;
					font-size: 12px;
					&.user-info .user-name { line-height: 14px; }
				}
			}
		}
	}
}
