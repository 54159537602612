// Main analytics dashboard + monthly report
.dash-tabs-ctn {
	display: flex;
	flex-wrap: wrap;
}

.dash-tabs-ctn, .performances-ctn, .campaign-ctn {
  .nav-tabs {
    width: 100%;
    background-color: #ECF0F6;
  }

	.nav-item {
    margin: 0;
		border: 0 none;
    
    .nav-link {
      height: 70px;
      margin: 0;
      padding: 18px 40px;
      border: 0 none;
      border-top: 6px solid #ECF0F6;
      border-radius: 0;
      font-family: "Open Sans";
      font-size: 17px;
      font-weight: 600;
      line-height: 23px;
      color: #3D3F43;

      &.active, &:hover { 
        border-top-color: #E4B448;
        color: #937227;
      }
    }
	}

	// Search controls 
	.analytics-filter-ctn {
        padding: 0px;
		margin-bottom: 50px;

		.search-properties-ctn {
			display: flex;
			align-items: flex-end;
			justify-content: flex-start;
			flex-wrap: wrap;
			gap: 20px;
			margin: 0px;

			.form-group { 
				width: 160px;
				margin: 0 !important;
			}

			.btn-primary { height: 45px; }
		}
	}

	// Main tab content
	.tab-content {
    padding: 0px !important;
		background: none;
		border: 0 none;
		width: 100%;
		margin-top: 30px;
		box-shadow: none;
	}

	// White blocs
	.content-bloc {
		padding: 50px;
		background-color: #FFFFFF;
		box-shadow: 0 7px 15px 0 rgba(0,0,0,0.05);

    .bloc-header {
      flex: 1 0 100%;
      text-align: left;
      margin-bottom: 30px;
      .title { margin: 0; }
    }

		.title {
			margin: 0 0 30px;
			font-size: 22px;
			font-weight: bold;
			line-height: 30px;
		}

    &.plain {
      box-shadow: none;
      padding: 12px;
      .title {
        font-size: 18px;
        small {
			display: block;
			color: $green;
			font-weight: 700;
		}
		a {
			color: $green;
		}
      }
    }
	}
	
	// Specific blocs
	.numbers-info {
		display: flex;
		align-items: center;
		text-align: center;
    	flex-wrap: wrap;

		&.-two-columns {
			.number-item {
				width: calc(100% / 2);
			}
		}

		.number-item {
			width: calc(100% / 3);
			padding: 20px;
			border-right: solid 1px #D8D8D8;

			&:last-child { border-right: 0; }

			.number {
				margin: 10px 0px 20px;
				font-size: 26px;
				font-weight: 600;
				line-height: 36px;
			}

			.legend {
				margin: 0;
				font-size: 11px;
				line-height: 15px;
				letter-spacing: 1px;
				font-weight: 600;
				color: #8898AA;
				text-transform: uppercase;
			}

			i {
				width: 32px;
				height: 33px;
				margin: 0 auto 10px;
			}
		}	
	}

  .sell-graph {
    margin-top: 50px;
    #soldedGraph { margin-top: 60px; }
  }

	.graphs-ctn {
		display: flex;
		justify-content: space-between;
		padding-top: 50px;

		.graph-bloc {
			width: calc((100% / 2) - 20px );

			.row {
				display: flex;
				align-items: center;
			}

			.doughnut-chart {
				max-width: 250px;
				margin: 0 0 0 auto;
			}
		}
	}

	.list-stats-ctn {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			margin-top: 30px;
			padding-bottom: 30px;
			border-bottom: solid 1px #D8D8D8;

			

			.label-ctn {
				display: flex;
				align-items: center;
				width: calc(100% - 50px);

				.color-legend {
					margin-right: 15px;
					display: block;
					width: 20px;
				}
			}

			.label {
				height: 20px;
				font-size: 15px;
				color: #64696F;
				font-family: 'Open Sans', sans-serif;
				font-weight: 400;
				padding: 0 10px 0 0;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}

			.data {
				font-size: 18px;
				font-weight: 600;
				line-height: 24px;
				text-align: right;
				font-family: 'Open Sans', sans-serif;
			}

			&.small{

				margin-top: 15px;
				padding-bottom: 15px;
			}
		}
	}

	.list-info-full {
		padding-top: 25px;
		padding-right: 5px;
		padding-left: 0;

		.list-stats-ctn{
			display: flex;
			flex-wrap: wrap;
			align-items: stretch;

			li {
				width: calc((100% / 3 - 40px));
				margin-right: 60px;
				&:nth-child(3n+3) { margin-right: 0; }
			}
		}
	}

	.visits-chart-ctn {
		padding-top: 15px;
		padding-right: 5px;
		padding-left: 0;
		.line-chart { margin-top: 25px; }
	}

	
	@media (max-width: 1400px) {
		.graphs-ctn .graph-bloc {
			.row { flex-direction: column-reverse; }
			.col-md-6 { width: 100%; }
			.doughnut-chart { margin: 0 auto; }
		}
	}

	@media (max-width: 1200px) {
		.list-info-full .list-stats-ctn {
			li {
				width: calc((100% / 2 - 30px));
				margin-right: 60px;

				&:nth-child(3n + 3) { margin-right: 60px; }
				&:nth-child(even) { margin-right: 0; }
			}
		}
	}

	@media (max-width: 1080px) {
		.graphs-ctn {
			flex-wrap: wrap;

			.graph-bloc {
				width: 100%;
				margin-bottom: 60px;

				&:last-child { margin-bottom: 0; }
				.row { flex-direction: row; }
				.col-md-6 { width:100%; }
			}
		}
	}

	@media only screen and (max-width: 767px) {
    .nav-item .nav-link {
      height: auto;
      padding: 15px 16px;
      font-size: 14px;
    }

		.tab-content {
      .content-bloc {
        padding: 30px 20px;
      }

			.numbers-info {
				flex-wrap: wrap;
				justify-content: center;

				.number-item{
					width: calc(50% - 20px);
					margin-bottom: 30px;

					&:nth-child(even) { border-right: 0; }

					&:last-child{
						margin-bottom: 0;
						margin-top: 20px;
					}
				}
			}
		}

		.graphs-ctn {
			.graph-bloc {
				.row { flex-direction: column-reverse; }
				.col-md-6 { width: 100%; }
			}	
		}

		.list-info-full .list-stats-ctn li {
			width: 100%;
			margin-right: 0 !important;
		}
	}

	@media only screen and (max-width: $xs-small) {
		.analytics-filter-ctn .search-properties-ctn {
			justify-content: center;
			padding: 40px 14px 0;
		}
	}

	@media only screen and (max-width: 400px) {
    .nav-item .nav-link {
      font-size: 11px;
    }
	}
}

#campaigns {
	.add-button {
		display: flex;
		flex-wrap: wrap;
		min-width: 100%;
		margin-bottom: 20px;
		justify-content: flex-end;
	}
  
	.content-bloc {
		overflow: hidden;
		
    	&.campaign-elem {
			margin-bottom: 20px;
			position: relative;
			padding: 0;
		}

		.card-header, .card-body {
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
			align-items: center;
			padding: 15px 27px;

			.datetime{
				margin: 0;
			}

			.badge-pill{
				border-radius: 10rem;
				font-weight: 700;
				font-size: 13px;
				line-height: 16px;
				padding: 5px 10px;

				i{
					margin-right: 6px;
				}
			}
		}

		.card-actions{
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
			align-items: center;
			padding: 22px 27px;

			a {
				color: $black;

				.la-angle-up{
					font-size: 16px;
					margin-left: 10px;
					transition: all 0.4s ease;
				}

				&.collapsed{
					.la-angle-up{
						transform: rotate(180deg);
					}
				}
			}
			.button-action{
				display: flex;
				flex-wrap: nowrap;
				.btn {
					border: 1px solid hsla(210,5%,55%,.3);
					border-radius: 5px;
					font-weight: 600;
					color: $icon-color;
					font-size: 13px;
					line-height: 18px;
					margin-left: 10px;
					
					&:hover {
						border-color: $green;
						background-color: $green;
						color: white;
						i {
							color: white;
						}
					}
					i {
						width: 100%;
						color: $icon-color;
						font-size: 20px;

						&.la-edit{
							margin-right: 0;
						}
					}
				}
			}
		}

		.delete-action {

			display: flex;
			justify-content: flex-end;
			margin-bottom: 30px;

			.btn-delete{
				border: solid 1px rgba(0, 0, 0, 0.6);
				color: rgba(0, 0, 0, 0.6);
				box-shadow: none;
				font-weight: 700;

				i{
					font-size: 20px;
				}
			}
		}

		.bg-color{
			background-color: rgba(#EEF0F2, 0.5);
			padding: 30px;
	
			.title{
				span{
					font-weight: 600;
					font-size: 11px;
					line-height: 15px;
					color: #8898AA;
				}
			}
		}
	}
}



// Fix gutenberg forced fixed scrolling <782px
html.interface-interface-skeleton__html-container { position: initial !important; } 