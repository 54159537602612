.app-footer { 
   height: 50px;
   margin-top: -50px;
   padding: 70px 0;

   .footer-ctn {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0 50px;
      font-size: 14px;

      div {
         color: $text-color-light;
         
         a { 
            color: $green;
            font-weight: 700;
         }
      }
   }

   @media (max-width: $medium) {
      margin-top: 0;
      padding: 0 !important;
      .footer-ctn { padding: 30px 10px; }
   }

   @media (max-width: $xs-small) {
      .footer-ctn { 
         flex-direction: column;
         align-items: center;
      }
   }
}

// Add sidebar padding when open
html .sidebar-lg-show .app-footer { 
   padding-left: $sidebar-width;
}