// Adjust sidebar-dependant global margin
html:not([dir="rtl"]) .sidebar { margin-left: calc(#{$sidebar-width} * -1); }

.app-body .sidebar.sidebar-pills {
    width: $sidebar-width;
    min-height: 100vh;
    height: 100%;
    position: absolute;
    top: $header-height;
    padding: 50px 0 0;
    background-color: white !important;
    box-shadow: 10px 0 10px 0 rgba(0,0,0,0.04);

    hr { margin: 20px 26px; }
    
    .sidebar-nav, .nav { width: 100%; }
    
    .nav .nav-item {
        .nav-link {
            padding: 10px 14px 10px 28px;
            transition: $mainTransition;

            &.active { font-weight: 600; }
            &.active, &:hover {
                color: $green !important;
                background-color: $sidebar-hover-color;
            }

            // Fix icon position and size
            .nav-icon {
                position: relative;
                top: 2px;
                margin-right: 10px;
                font-size: 20px;
                color: $green;
            }

            // Rotate caret for selected sub-menus
            &.nav-dropdown.active i.fa-angle-right {
                top: -5px;
                transform: rotate(90deg);
            }

            // Remove purple hover color
            &:hover .nav-icon { color: $dark-green !important; }
        }

        // Adjust submenu padding
        .nav-dropdown-items .nav-link { padding-left: 54px; }

        //
        &.impersonating {
          * { color: #E4B448 !important; }
        }
    }

    // Yellow star for Prestige features
    .star-prestige {
        display: inline-block;
        background-image: url("../images/star.svg");
        background-repeat: no-repeat;
        background-size: 75%;
        background-position: center;
        min-height: 15px;
        min-width: 15px;
        margin-left: 10px !important;
    }
}
