.sidebar-menu i[class^="icon-"], .sidebar-menu i.fa {
    position: absolute;
    width: 20px;
    height: 20px;
}

i[class^="icon-"]  {
    display: inline-block;
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

i.icon- {

    // Sidebar
    &control-panel {
        background-image: url('../../images/icon/icone-tableau-de-bord.svg');
        display: inline-block;
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    &contents {
        background-image: url('../../images/icon/icone-contenus.svg');
        display: inline-block;
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    &team {
        background-image: url('../../images/icon/icone-equipe.svg');
        display: inline-block;
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    &blog {
        background-image: url('../../images/icon/icone-blogue.svg');
        display: inline-block;
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }

    &inscriptions {
        background-image: url('../../images/icon/icone-inscriptions.svg');
        display: inline-block;
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    &neighborhoods {
        background-image: url('../../images/icon/icone-quartiers.svg');
        display: inline-block;
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    &projects {
        background-image: url('../../images/icon/icone-projets-immobiliers.svg');
        display: inline-block;
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    &homestaging {
        background-image: url('../../images/icon/icone-homestaging.svg');
        display: inline-block;
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }

    &distinctions {
        background-image: url('../../images/icon/icone-distinctions.svg');
        display: inline-block;
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    &testimonies {
        background-image: url('../../images/icon/icone-temoignages.svg');
        display: inline-block;
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    &partners {
        background-image: url('../../images/icon/icone-partenaires.svg');
        display: inline-block;
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    &featured {
        background-image: url('../../images/icon/icone-blocs-vedettes.svg');
        display: inline-block;
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }

    &contact {
        background-image: url('../../images/icon/icone-demande-contact.svg');
        display: inline-block;
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    &reports {
        background-image: url('../../images/icon/icone-rapports-visites.svg');
        display: inline-block;
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    &publications {
        background-image: url('../../images/icon/icone-publications.svg');
        display: inline-block;
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }

    //
    &age {
        background-image: url('../../images/icon/icone-age.svg');
        display: inline-block;
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    &duration {
        background-image: url('../../images/icon/icone-duree.svg');
        display: inline-block;
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    &visits {
        background-image: url('../../images/icon/icone-visites.svg');
        display: inline-block;
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    &user {
        background-image: url('../../images/icon/icone-user.svg');
        display: inline-block;
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
}