.register-ctn {
   .register-brand {
      display: flex;
      align-content: center;
      justify-content: center;
      padding-top: 20px;
      padding-bottom: 20px;
      img {
         max-width: 260px;
      }
   }
   form {
      padding: 0px 30px 20px 30px;  
      .btn-reset {
         margin-left: 5px;
      }
   }

   .button-ctn {
      margin-top: 20px;
   }

   .text-center {
      margin-bottom: 10px;
   }
}
