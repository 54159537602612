// Adjust sidebar-dependant global margin
html:not([dir="rtl"]) .sidebar-lg-show .main { margin-left: $sidebar-width; }

.main {
    margin-left: 0;
    padding: 50px !important;

    // Override useless padding
    .container-fluid { padding: 0; }

    // Main content (White shadowy box)
    .content-box {
        border-radius: 5px;
        background-color: white;
        -webkit-box-shadow: $content-shadow; 
        box-shadow: $content-shadow;

        .content-box-header {
            padding: 30px;

            // Page title
            h1 {
                margin: 0;
                font-size: 20px;
                font-weight: 600;
                line-height: 27px;

                // Back link
                a.d-print-none.font-sm {
                    color: $green;
                    font-size: 1em;
                }
            }

            // CRUD Buttons & search field
            > div.hidden-print {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                margin-top: 25px;
            }

            // CRUD Show Content
            .card {
                margin-top: 40px;
                border: 0 none !important;
                box-shadow: none;

                .table {
                    tr { background: none !important; }
                    td { 
                        padding: 10px;
                        border: 0 none !important;
                        &:first-child { min-width: 200px; }
                    }
                }
            }
        }

        // CRUD table filters
        .navbar-filters { 
            padding: 0 26px;

            .nav-link.dropdown-toggle, 
            #remove_filters_button {
                background: none !important;
                &::after { display: none; } // Hide duplicated arrow
            }

            .dropdown-menu .input-group-prepend {
              display: none;
            }
        }

        .content-box-content {
            background-color: #EEF0F2;

            .tab-container { margin: 0 !important; }

            // Form tabs header
            .nav-tabs { 
//                 padding: 0;
                border: 0 none white !important;
                background-color: #d5d8db;
                
                > li { 
                    margin-bottom: 0;
                }
                
                .nav-link {
                    position: relative;
                    margin: 0;
                    padding: 10px 30px;
                    border: 0 none #EEF0F2 !important;
                    border-radius: 0;
                    box-sizing: border-box;
                    transition: $mainTransition;

                    &.active {
                        color: $green;
                        border-left: 1px solid #EEF0F2 !important;
                        border-right: 1px solid #EEF0F2 !important;
                        background-color: #EEF0F2;

                        &::before { 
                            position: absolute;
                            content: "";
                            top: 0;
                            left: -1px;
                            display: block;
                            width: calc(100% + 2px);
                            height: 3px;
                            background-color: $green;
                        }
                    }
                }
            }

            // Content outside tabs
            .card {
                margin: 0;
                border: none;
                background: none;

                .card-body { 
                    margin: 0;
                    padding-top: 40px;
                }

                .checklist_dependency > .container { width: 100%; }
            }

            // Form tabs content
            .tab-content {
                background: none;
                border: 0 none;
                padding: 30px 30px 0 !important;
                box-shadow: none;

                .tab-pane { padding: 0; }
            }

            // Form tabs footer
            #saveActions { 
                display: flex;
                gap: 12px;
                margin: 0 !important;
                padding: 40px 30px 60px;

                .btn { height: 45px !important; }
            }
        }
    }

    @media (max-width: $medium) {
        margin-left: 0 !important;
    }

    @media (max-width: $xs-small) {
        padding: 0 !important;

        .content-box { 
            border-radius: 0; 

            .content-box-header {
                > div.hidden-print {
                    flex-direction: column;
                }
            }
        }
    }
}
